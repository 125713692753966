import { BASE_SERVICE } from '../../VbBaseService'

const nameSpace = '/billing/'

export const BillingService = {
  ...BASE_SERVICE,
  // create (payload) {
  //   return this.post(`${nameSpace}`, payload)
  // },
  // image (id, payload, type) {
  //   return this.post(`${nameSpace}${id}?type=${type}`, payload)
  // },
  index (year, month) {
    return this.get(`${nameSpace}?year=${year}&month=${month}`)
  },
  show (id) {
    return this.get(`${nameSpace}${id}`)
  },
  editState (id) {
    return this.put(`${nameSpace}state/${id}`)
  },
  generateQr (id) {
    return this.put(`${nameSpace}generate-qr/${id}`)
  },
  payment (id, payload) {
    return this.put(`${nameSpace}payment/${id}`, payload)
  }
  // destroy (id) {
  //   return this.delete(`${nameSpace}${id}`)
  // }
}
