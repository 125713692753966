var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"dark":"","color":"#ff5a5f","small":""},on:{"click":_vm.goBack}},[_c('v-icon',[_vm._v("fas fa-arrow-left")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('h1',[_vm._v("Editar proyecto")])])],1),_c('v-card',{staticClass:"my-5"},[_c('v-card-title',[_vm._v(" Cuenta y Perfíl ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitProfile($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Título del proyecto","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","placeholder":"Título","persistent-placeholder":"","prepend-icon":"fas fa-pen","outlined":"","dense":""},model:{value:(_vm.postAccount.title),callback:function ($$v) {_vm.$set(_vm.postAccount, "title", $$v)},expression:"postAccount.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Descripción del proyecto","auto-grow":"","outlined":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-pen","rows":"2","row-height":"20"},model:{value:(_vm.postAccount.description),callback:function ($$v) {_vm.$set(_vm.postAccount, "description", $$v)},expression:"postAccount.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Brief","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Brief del proyecto (enlace de drive)","required":"","persistent-placeholder":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","prepend-icon":"fas fa-link","placeholder":"Brief (enlace de drive)","outlined":"","dense":""},model:{value:(_vm.postProfile.brief),callback:function ($$v) {_vm.$set(_vm.postProfile, "brief", $$v)},expression:"postProfile.brief"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-4 mt-2"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Bio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Bio de la cuenta","auto-grow":"","persistent-placeholder":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","prepend-icon":"fas fa-pen","outlined":"","rows":"2","row-height":"20"},model:{value:(_vm.postProfile.bio),callback:function ($$v) {_vm.$set(_vm.postProfile, "bio", $$v)},expression:"postProfile.bio"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"AccountUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Url de la cuenta","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","required":"","persistent-placeholder":"","prepend-icon":"fas fa-link","placeholder":"Url de la cuenta","outlined":"","dense":""},model:{value:(_vm.postProfile.account_url),callback:function ($$v) {_vm.$set(_vm.postProfile, "account_url", $$v)},expression:"postProfile.account_url"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Linktree","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"LinkTree de la cuenta","persistent-placeholder":"","error-messages":errors,"persistent-hint":"","prepend-icon":"fas fa-link","placeholder":"LinkTree","outlined":"","dense":""},model:{value:(_vm.postProfile.link_tree),callback:function ($$v) {_vm.$set(_vm.postProfile, "link_tree", $$v)},expression:"postProfile.link_tree"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-user","placeholder":"Nombre","outlined":"","dense":""},model:{value:(_vm.postProfile.name),callback:function ($$v) {_vm.$set(_vm.postProfile, "name", $$v)},expression:"postProfile.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Cellphone","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Teléfono","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-phone","placeholder":"Teléfono","outlined":"","dense":""},model:{value:(_vm.postProfile.cellphone),callback:function ($$v) {_vm.$set(_vm.postProfile, "cellphone", $$v)},expression:"postProfile.cellphone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","required":"","persistent-placeholder":"","prepend-icon":"fas fa-envelope","placeholder":"Correo electrónico","outlined":"","dense":""},model:{value:(_vm.postProfile.email),callback:function ($$v) {_vm.$set(_vm.postProfile, "email", $$v)},expression:"postProfile.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.socialMediaItems,"item-text":"name","return-object":"","persistent-placeholder":"","prepend-icon":"fas fa-link","menu-props":{ maxHeight: '400' },"label":"Redes sociales","multiple":"","dense":"","outlined":"","persistent-hint":""},model:{value:(_vm.postProfile.social_media),callback:function ($$v) {_vm.$set(_vm.postProfile, "social_media", $$v)},expression:"postProfile.social_media"}})],1)],1)],1),_vm._l((_vm.postProfile.social_media),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":item.name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.name,"persistent-placeholder":"","prepend-icon":item.name == 'Facebook' ? 'fab fa-facebook' : item.name == 'Instagram' ? 'fab fa-instagram' : 'fas fa-globe',"required":"","placeholder":"Redes sociales","outlined":"","dense":""},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}})]}}],null,true)})],1)})],2),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingProfile,"type":"submit","disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)],1)]}}])})],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Contrato ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitContract($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"PaymentFrequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Item is required'; }],"items":_vm.paymentFrequencyItems,"label":"Frecuencia de pago","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-file-invoice-dollar","placeholder":"Frecuencia de pago","outlined":"","dense":""},model:{value:(_vm.postContract.payment_frequency),callback:function ($$v) {_vm.$set(_vm.postContract, "payment_frequency", $$v)},expression:"postContract.payment_frequency"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ContractPeriod","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Item is required'; }],"items":_vm.contractPeriodItems,"error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-file","label":"Cantidad de meses del contrato","placeholder":"Cantidad de meses del contrato","outlined":"","dense":"","suffix":"Meses"},on:{"change":_vm.finishedAtSelect},model:{value:(_vm.postContract.contract_period),callback:function ($$v) {_vm.$set(_vm.postContract, "contract_period", $$v)},expression:"postContract.contract_period"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"PaymentType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Item is required'; }],"items":_vm.paymentTypeItems,"error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-wallet","label":"Tipo de pago","placeholder":"Tipo de pago","outlined":"","dense":""},on:{"change":_vm.paymentTypeSelect},model:{value:(_vm.postContract.payment_type),callback:function ($$v) {_vm.$set(_vm.postContract, "payment_type", $$v)},expression:"postContract.payment_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"Quotas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de cuotas","required":"","readonly":"","persistent-placeholder":"","prepend-icon":"fas fa-hashtag","placeholder":"Número de cuotas","outlined":"","dense":"","suffix":"Cuotas"},model:{value:(_vm.postContract.quotas),callback:function ($$v) {_vm.$set(_vm.postContract, "quotas", $$v)},expression:"postContract.quotas"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ContractAmount","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Pago mensual","required":"","readonly":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-dollar-sign","placeholder":"Pago mensual","outlined":"","dense":"","suffix":"Bs."},model:{value:(_vm.postContract.amount),callback:function ($$v) {_vm.$set(_vm.postContract, "amount", $$v)},expression:"postContract.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"ContractUrl","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Link drive del contrato","required":"","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","prepend-icon":"fas fa-link","placeholder":"Link drive del contrato","outlined":"","dense":""},model:{value:(_vm.postContract.url),callback:function ($$v) {_vm.$set(_vm.postContract, "url", $$v)},expression:"postContract.url"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"StartedAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de inicio del contrato y pago","prepend-icon":"fas fa-calendar","error-messages":errors,"hint":"(*) Este campo es obligatorio","persistent-hint":"","persistent-placeholder":"","placeholder":"Fecha de inicio del contrato y pago","readonly":"","outlined":"","dense":""},model:{value:(_vm.postContract.started_at),callback:function ($$v) {_vm.$set(_vm.postContract, "started_at", $$v)},expression:"postContract.started_at"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":_vm.finishedAtSelect},model:{value:(_vm.postContract.started_at),callback:function ($$v) {_vm.$set(_vm.postContract, "started_at", $$v)},expression:"postContract.started_at"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"FinishedAt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de finalización del contrato","prepend-icon":"fas fa-calendar","readonly":"","persistent-placeholder":"","placeholder":"Fecha de finalización del contrato","outlined":"","readonly":"","dense":""},model:{value:(_vm.postContract.finished_at),callback:function ($$v) {_vm.$set(_vm.postContract, "finished_at", $$v)},expression:"postContract.finished_at"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.postContract.finished_at),callback:function ($$v) {_vm.$set(_vm.postContract, "finished_at", $$v)},expression:"postContract.finished_at"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.loadingContract,"disabled":invalid}},[_vm._v(" Guardar ")])],1)],1)],1)]}}])})],1)],1),_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Facturación ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.billing),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(item.month)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":'PaymentDate'+index,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de pago","prepend-icon":"fas fa-calendar","readonly":"","persistent-placeholder":"","placeholder":"Fecha de pago","outlined":"","readonly":"","dense":""},model:{value:(item.meta.paymentDate),callback:function ($$v) {_vm.$set(item.meta, "paymentDate", $$v)},expression:"item.meta.paymentDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(item.meta.menu),callback:function ($$v) {_vm.$set(item.meta, "menu", $$v)},expression:"item.meta.menu"}},[_c('v-date-picker',{on:{"input":function($event){item.meta.menu = false}},model:{value:(item.meta.paymentDate),callback:function ($$v) {_vm.$set(item.meta, "paymentDate", $$v)},expression:"item.meta.paymentDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(" Monto: "+_vm._s(item.amount)+" Bs. ")])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","block":""},on:{"click":function($event){return _vm.submitPayment(item)}}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }