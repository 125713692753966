import { ProjectService } from './video-brand-services/projects/projects.service'
import { BillingService } from './video-brand-services/billing/billing.service'

const Services = {
  ProjectService,
  BillingService
}

export const VbServiceFactory = {
  get: name => Services[name]
}
