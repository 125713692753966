import { BASE_SERVICE } from '../../VbBaseService'
import { buildFormData } from '../VbBuildFormData.service'

const nameSpace = '/project/'

export const ProjectService = {
  ...BASE_SERVICE,
  exclusiveVideo (payload) {
    return this.post(`${nameSpace}exclusive-video`, payload)
  },
  create (payload) {
    return this.post(`${nameSpace}`, payload)
  },
  getContractTypes () {
    return this.get(`${nameSpace}contract-type`)
  },
  image (payload) {
    const auxFormData = buildFormData(payload)
    return this.put(`${nameSpace}image`, auxFormData)
  },
  editAccount (id, payload) {
    return this.put(`${nameSpace}account/${id}`, payload)
  },
  editProfile (id, payload) {
    return this.put(`${nameSpace}profile/${id}`, payload)
  },
  editContract (id, payload) {
    return this.put(`${nameSpace}contract/${id}`, payload)
  }
  // index () {
  //   return this.get(`${nameSpace}`)
  // }
  // show (id) {
  //   return this.get(`${nameSpace}${id}`)
  // }
  // edit (id, payload) {
  //   return this.put(`${nameSpace}${id}`, payload)
  // },
  // destroy (id) {
  //   return this.delete(`${nameSpace}${id}`)
  // }
}
