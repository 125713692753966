import { VbServiceFactory } from '@/services/VbServiceFactory'
import moment from 'moment'
const _billingService = VbServiceFactory.get('BillingService')
const _projectService = VbServiceFactory.get('ProjectService')

export default {
  name: 'VbProjectForm',
  data () {
    return {
      loadingAccount: false,
      loadingProfile: false,
      loadingContract: false,
      projectId: '',
      projectTypeItems: ['Proyecto interno', 'Proyecto externo'],
      paymentFrequencyItems: ['Mensual'],
      contractPeriodItems: [1, 2, 3, 4, 5, 6],
      paymentTypeItems: ['Cuotas', 'Al contado'],
      platformTypeItems: ['TikTok'],
      socialMediaItems: [
        {
          name: 'Instagram',
          url: '',
          icon: 'fab fa-instagram'
        },
        {
          name: 'Facebook',
          url: '',
          icon: 'fab fa-facebook'
        },
        {
          name: 'Página web',
          url: '',
          icon: 'fas fa-globe'
        }
      ],
      postAccount: {
        'vbContractTypeId': '',
        'projectType': 'Proyecto interno',
        'businessName': '',
        'businessType': '',
        'businessId': '',
        'title': '',
        'description': ''
      },
      postProfile: {
        'platform_type': 'TikTok',
        'accountUrl': '',
        'bio': '',
        'brief': '',
        'linkTree': '',
        'name': '',
        'email': '',
        'cellphone': '',
        'socialMedia': ''
      },
      postContract: {
        'payment_frequency': '',
        'contract_period': '',
        'payment_type': '',
        'quotas': '',
        'amount': '',
        'url': '',
        'started_at': '',
        'finished_at': ''
      },
      menu: false,
      menu2: false,
      billing: []
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        console.log(this.$route.params.id)
        this.projectId = this.$route.params.id
        try {
          const response = await _billingService.show(this.$route.params.id)
          console.log(response)
          this.billing = response.billings
          this.project = response.project
          this.postAccount = response.project.account
          this.postProfile = response.project.profile
          this.postContract = response.project.contract
        } catch (error) {
          console.log(error)
        }
      } else {
        this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
      }
    },
    goBack () {
      this.$router.push({ name: 'info', params: { id: this.projectId } }).catch(() => {})
    },
    paymentTypeSelect (e) {
      if (e === 'Cuotas') {
        this.postContract.quotas = '2'
      } else {
        this.postContract.quotas = '1'
      }
    },
    finishedAtSelect () {
      this.menu2 = false
      if (this.postContract.contract_period === '') {
        this.postContract.finished_at = this.postContract.started_at
      } else {
        const finDate = moment(this.postContract.started_at).add(this.postContract.contract_period, 'M').format('YYYY-MM-DD')
        this.postContract.finished_at = finDate
      }
    },
    async submitAccount () {
      try {
        this.loadingAccount = true
        console.log(this.postAccount)
        const response = await _projectService.editAccount(this.postAccount.id, this.postAccount)
        console.log(response)
        this.$swal(
          'Cuenta editada correctamente',
          '',
          'success'
        ).then(async (result) => {
          // this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
          this.loadingAccount = false
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.loadingAccount = false
        this.initialize()
      }
    },
    async submitProfile () {
      try {
        this.loadingProfile = true
        console.log(this.postAccount)
        const responseAccount = await _projectService.editAccount(this.postAccount.id, this.postAccount)
        console.log(responseAccount)

        console.log(this.postProfile)
        const response = await _projectService.editProfile(this.postProfile.id, this.postProfile)
        console.log(response)
        this.$swal(
          'Perfil editado correctamente',
          '',
          'success'
        ).then(async (result) => {
          // this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
          this.loadingProfile = false
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.loadingProfile = false
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al editar el perfil, vuelva a intentarlo nuevamente'
        })
        this.initialize()
      }
    },
    async submitContract () {
      try {
        this.loadingContract = true
        console.log(this.postContract)
        this.$swal(
          {
            title: 'Se eliminará y se generará la facturación. Desea continuar?',
            showCancelButton: true,
            confirmButtonText: 'Si'
          }
        ).then(async (result) => {
          if (result.isConfirmed) {
            const response = await _projectService.editContract(this.postContract.id, this.postContract)
            console.log(response)
            this.$swal(
              'Contrato editado correctamente',
              '',
              'success'
            ).then(async (result) => {
              // this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
              this.loadingContract = false
            })
            this.initialize()
          }
        })
        this.loadingContract = false
      } catch (error) {
        console.log(error)
        this.loadingContract = false
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al editar el contrato, vuelva a intentarlo nuevamente'
        })
        this.initialize()
      }
    },
    async submitPayment (item) {
      try {
        // this.loadingProfile = true
        console.log(item)
        var payload = {
          'amount': item.amount,
          'month': item.month,
          'paymentDate': item.meta.paymentDate
        }
        const response = await _billingService.payment(item.vb_billing_id, payload)
        console.log(response)
        this.$swal(
          'Perfil editado correctamente',
          '',
          'success'
        ).then(async (result) => {
          // this.$router.push({ name: 'reserbar', params: { view: 'videobrand' } }).catch(() => {})
          // this.loadingProfile = false
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        // this.loadingProfile = false
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al editar la fecha de pago, vuelva a intentarlo nuevamente'
        })
        this.initialize()
      }
    }
  }
}
